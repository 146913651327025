import React from 'react';
import { useEffect } from 'react';
import Layout from 'components/layout';
import { useHeap } from 'components/utils/heap';
import { REVALIDATE_TIME } from 'lib/constants';
import { getWPPageBySlug } from 'lib/api';
// import Link from 'next/link';

// This is the Next.JS 404 page

export default function Page({ post, meta, title, jsonld, nav, footer }) {
    useEffect(() => {
        if (window) {
            // GTM DATALAYER PUSH FOR 404 PAGE VIEWS
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': '404-page',
                'value': '404-page',
                'event-details': 'NextJS-404-page'
            });
        }
    }, []);

    // HEAP TRACKING FOR 404 PAGE VIEWS
    const heap = useHeap();
    useEffect(() => {
        heap.track('Marketing - 404 page', { "Event details": "NextJS 404 page" }); 
    }, [heap]);

    if (!post) {
        return <div>Loading</div>;
    }

    return (
        <Layout
            jsonld={jsonld}
            meta={meta}
            title={title}
            home={true}
            nav={nav}
            footer={footer}>

            <div className="bgtightblue bb mb-6">
                <div className="container u-vSpacingLG">
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-md-6 text-center">
                            <h1 className="jumbo mt-4 mb0 pbp5">Oops, we can&apos;t find what you&apos;re looking for.</h1>
                            <h3 className="jumbo mt-4 mb0 pbp5">404 page not found</h3>
                            <p className="mt-4 mb0 pbp5">Sorry about that. You can go back <a href="/?from-404">home</a>, send us a message via our <a href="/support/#support/?from-404">support page</a> or get in touch with our friendly <a href="/contact/?from-404">team</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export async function getStaticProps({ params }) {
    // console.log(params);
    const post = await getWPPageBySlug('about');
    // const post = posts[0];
    const meta = [];
    const title = 'Page not found | Timely';
    const jsonld = false;
    const nav = post.nav;
    const footer = post.footer;
    return {
        props: {
            meta,
            title,
            post,
            jsonld,
            nav,
            footer,
        },
        revalidate: REVALIDATE_TIME,
    };
}
